import { Notification } from "./models/Notification";
import { IAccount } from "./models/IAccount";

export const getNotificationMessage = (store: any): Notification => {
  return store.notificationMessage;
};

export const getAccount = (store: any): IAccount => {
  return store.accountReducer.account;
};

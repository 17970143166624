import axios from "axios";
import { ICreatePollVoteRequestBodyDto } from "../../dto/poll/ICreatePollVoteRequestBodyDto";

export const ALLOW_LIST_ERROR_MESSAGES = [
  "You do not have permission to vote in this poll",
];
class AnswerPollService {
  static async getPoll(pollId: string) {
    const poll = await axios.get(
      `${process.env.REACT_APP_API_URL}/poll/answer?id=${pollId}`
    );
    return poll.data;
  }

  static async saveVote(
    pollId: string,
    optionId: number,
    pollPermissionId: string
  ): Promise<boolean> {
    const body: ICreatePollVoteRequestBodyDto = {
      pollId: pollId,
      optionId: optionId,
      permissionId: pollPermissionId,
    };
    try {
      const savedVote = await axios.post(
        `${process.env.REACT_APP_API_URL}/poll/vote`,
        body
      );
      if (savedVote.status === 200) {
        return true;
      }
    } catch (e: any) {
      throw new Error(e?.response?.data?.error ?? "Unknown error");
    }
    return false;
  }
}

export default AnswerPollService;

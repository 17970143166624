import { createSlice } from "@reduxjs/toolkit";
import { IYoutubeComment } from "../../model/youtube/IYoutubeComment";

interface YoutubeCommentState {
  loading: boolean;
  commentThreads: {
    [commentThreadVideoId: string]: IYoutubeComment[];
  };
}

const initialState = {
  loading: false,
  commentThreads: {},
} satisfies YoutubeCommentState as YoutubeCommentState;

const youtubeCommentSlice = createSlice({
  name: "youtube-comment",
  initialState,
  reducers: {
    updateNewCommentThreads(
      state,
      action: {
        payload: {
          commentThreadVideoId: string;
          commentThreads: IYoutubeComment[];
        };
        type: string;
      },
    ) {
      const newCommentThreadVideoId = action.payload.commentThreadVideoId;
      if (
        newCommentThreadVideoId === "" ||
        state.commentThreads[newCommentThreadVideoId] !== undefined
      ) {
        return;
      }

      state.commentThreads[newCommentThreadVideoId] =
        action.payload.commentThreads;
      state.loading = false;
    },
    updateYoutubeCommentLoading(
      state,
      action: { payload: boolean; type: string },
    ) {
      state.loading = action.payload;
    },
  },
});

export const { updateNewCommentThreads, updateYoutubeCommentLoading } =
  youtubeCommentSlice.actions;
export default youtubeCommentSlice.reducer;

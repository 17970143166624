import React from "react";
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
//@ts-ignore
import classNames from "classnames";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import Avatar from "@material-ui/core/Avatar";
import IconButton from "@material-ui/core/IconButton";
import red from "@material-ui/core/colors/red";
import FavoriteIcon from "@material-ui/icons/Favorite";
import Grid from "@material-ui/core/Grid";
import "./Article.css";
import { Loading } from "../../common/Loading";
import moment from "moment";
import { NewsEntity } from "../../../model/news/NewsEntity";

const style = (theme: any) => ({
  card: {
    // maxWidth: 400,
  },
  media: {
    height: 0,
    paddingTop: "56.25%", // 16:9
  },
  actions: {
    display: "flex",
  },
  expand: {
    transform: "rotate(0deg)",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
    marginLeft: "auto",
    [theme.breakpoints.up("sm")]: {
      marginRight: -8,
    },
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
  avatar: {
    backgroundColor: red[500],
  },
  favoriteIconColor: {
    color: red[500],
  },
});

export type ArticleProps = {
  classes: any;
  data: NewsEntity;
  isLoaded: boolean;
  error: boolean;
};

export type ArticleState = {
  expanded: boolean;
  favoriteIcon: boolean;
};

class Article extends React.Component<ArticleProps, ArticleState> {
  constructor(props: ArticleProps) {
    super(props);
    this.state = {
      expanded: false,
      favoriteIcon: false,
    };
  }

  handleExpandClick = () => {
    this.setState((state) => ({ expanded: !state.expanded }));
  };

  componentDidMount() {}

  changeFavoriteIcon = () => {
    this.setState({
      favoriteIcon: !this.state.favoriteIcon,
    });
  };

  render() {
    const { classes } = this.props;

    const dateModified = this.props?.data?.modified
      ? moment(this.props.data.modified).format("LLLL")
      : "";

    if (this.props.error) {
      return <p>error</p>;
    } else if (!this.props.isLoaded) {
      return (
        <Grid container>
          <Loading />
        </Grid>
      );
    } else {
      return (
        <Grid container>
          <Grid item xs={10}>
            <Card className={classes.card}>
              <CardHeader
                avatar={
                  <Avatar aria-label="Recipe" className={classes.avatar}>
                    A
                  </Avatar>
                }
                action={
                  <IconButton
                    onClick={this.changeFavoriteIcon}
                    aria-label="Add to favorites"
                  >
                    <FavoriteIcon
                      className={classNames({
                        [classes.favoriteIconColor]: this.state.favoriteIcon,
                      })}
                    />
                  </IconButton>
                }
                title={this.props.data.title}
                subheader={dateModified}
              />

              <CardContent
                className="articleContent"
                dangerouslySetInnerHTML={{ __html: this.props.data.content }}
              />
              <CardActions className={classes.actions}>
                {/*<IconButton*/}
                {/*  onClick={this.changeFavoriteIcon}*/}
                {/*  aria-label="Add to favorites"*/}
                {/*>*/}
                {/*  <FavoriteIcon*/}
                {/*    className={classNames({*/}
                {/*      [classes.favoriteIconColor]: this.state.favoriteIcon*/}
                {/*    })}*/}
                {/*  />*/}
                {/*</IconButton>*/}
                {/*<IconButton aria-label="Share">*/}
                {/*  <ShareIcon />*/}
                {/*</IconButton>*/}
                {/*<IconButton*/}
                {/*  className={classNames(classes.expand, {*/}
                {/*    [classes.expandOpen]: this.state.expanded*/}
                {/*  })}*/}
                {/*  onClick={this.handleExpandClick}*/}
                {/*  aria-expanded={this.state.expanded}*/}
                {/*  aria-label="Show more"*/}
                {/*>*/}
                {/*  <ExpandMoreIcon />*/}
                {/*</IconButton>*/}
              </CardActions>
              {/*<Collapse in={this.state.expanded} timeout="auto" unmountOnExit>*/}
              {/*<CardContent>*/}
              {/*</CardContent>*/}
              {/*</Collapse>*/}
            </Card>
          </Grid>
        </Grid>
      );
    }
  }
}

//@ts-ignore
Article.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(style)(Article);

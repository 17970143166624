import React from "react";
import Typography from "@material-ui/core/Typography/Typography";
import Favourite from "@material-ui/icons/Favorite";
import Grid from "@material-ui/core/Grid";

function Footer() {
  const [favouriteIconColor, setFavouriteIconColor] = React.useState<
    "inherit" | "primary" | "secondary" | "action" | "disabled" | "error"
  >("error");

  const onFavouriteIconMouseEnter = () => {
    setFavouriteIconColor("primary");
  };
  const onFavouriteIconMouseLeave = () => {
    setFavouriteIconColor("error");
  };

  return (
    <footer>
      <Grid container direction="row" alignItems="center">
        <Grid item>
          <Typography
            variant="subtitle1"
            style={{
              display: "flex",
              alignItems: "center",
              flexWrap: "wrap",
            }}
          >
            Made with&nbsp;
            <Favourite
              color={favouriteIconColor}
              onMouseEnter={() => onFavouriteIconMouseEnter()}
              onMouseLeave={() => onFavouriteIconMouseLeave()}
            />
            &nbsp;by Wilson.&nbsp;
            <a href="https://wilsonmun.statuspage.io">Service Status</a>
          </Typography>
        </Grid>
      </Grid>
    </footer>
  );
}

export default Footer;

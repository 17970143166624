export interface Notification {
  message: string;
  type: NotificationType;
}

export enum NotificationType {
  ERROR = "error",
  SUCCESS = "success",
  INFO = "info",
  WARNING = "warning",
}

import React, { useState } from "react";
import { useParams } from "react-router";
import Grid from "@material-ui/core/Grid";
import SaveIcon from "@material-ui/icons/Save";
import Button from "@material-ui/core/Button";
import { createPollVotePermission } from "./PollAdmin.service";
import Paper from "@material-ui/core/Paper";
import { pollAnswerRoutePrefix } from "../../constants";

export interface PollAdminProps {}
export function PollAdmin(props: PollAdminProps) {
  const { pollId } = useParams<{ pollId: string }>();
  const [pollPermissionIds, setPollPermissionIds] = useState<string[]>([]);

  const createPollVotePermissionForPoll = async (pollIdString: string) => {
    const result = await createPollVotePermission(pollIdString);
    if (result) {
      const newVotingPermissionString = result.id;
      setPollPermissionIds([...pollPermissionIds, newVotingPermissionString]);
    }
  };

  const allVotingLinks = pollPermissionIds.map((pollPermissionId, key) => {
    const currentHostLocation = window.location.origin;
    const linkToShare = `${currentHostLocation}${pollAnswerRoutePrefix}/${pollId}/permission/${pollPermissionId}`;
    return (
      <Grid item xs={12} key={key}>
        <a href={linkToShare}>
          Share this link with someone who you want their vote on this poll -{" "}
          {linkToShare}
        </a>
      </Grid>
    );
  });

  return (
    <Grid container>
      <Grid item xs={12}>
        <Button
          variant="contained"
          color="primary"
          size="small"
          startIcon={<SaveIcon />}
          onClick={() => createPollVotePermissionForPoll(pollId)}
        >
          Create new voting link
        </Button>
      </Grid>
      <Grid item xs={12}>
        <Paper style={{ background: "white" }}>{allVotingLinks}</Paper>
      </Grid>
    </Grid>
  );
}

import React from "react";

export function regexReplace(regexp, variableToMatch) {
  /* regex so we can extract the article id */
  let regex = new RegExp(regexp);
  let replacedVariable = "";

  if (variableToMatch.match(regex)) {
    /* extract out the string we want */
    replacedVariable = { id: variableToMatch.replace(regex, "$1") };
  }
  return replacedVariable;
}

export class ImageLoader extends React.Component {
  constructor(props) {
    super(props);
    // this.state = {imageDirList: []};
    this.state = {};
  }

  componentDidMount() {
    // let that = this;
    // $.ajax({
    //   url: process.env.REACT_APP_API_URL + "/load-images",
    //   method: "GET"
    // }).then(function (response) {
    //   /* create a new image element for each url passed back from callback */
    //   const listItems = response.map(
    //       (image) =>
    //           <img src={image} key={image.toString()} alt={image.toString()}/>
    //   );
    //   that.setState({imageDirList: listItems});
    // });
  }

  render() {
    return (
      <React.Fragment>
        <div>{this.state.imageDirList}</div>
      </React.Fragment>
    );
  }
}

export class Clock extends React.Component {
  constructor(props) {
    super(props);
    this.state = { timeDate: new Date().toLocaleString() };
  }

  componentDidMount() {
    this.interval = setInterval(
      () => this.setState({ timeDate: new Date().toLocaleString() }),
      1000
    );
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  render() {
    return (
      <div>
        <p>{this.state.timeDate}</p>
      </div>
    );
  }
}

import React from "react";
import { Link, withRouter } from "react-router-dom";

import "./NavigationBar.css";

function NavigationBar(props: any) {
  return (
    <div className="nav-bar">
      <Link to="/">
        <img
          className="logo"
          src={process.env.PUBLIC_URL + "/icon-192x192.png"}
          alt="Logo"
        />
      </Link>
    </div>
  );
}

export default withRouter(NavigationBar);

import { combineReducers } from "redux";
import notificationMessageReducer from "./notificationMessageReducer";
import accountReducer from "./accountReducer";
import youtubeCommentSliceReducer from "../slice/youtubeCommentSlice";
/**
 * combine all reducers here in this reducer folder
 */
export default combineReducers({
  notificationMessage: notificationMessageReducer,
  accountReducer,
  youtubeCommentSliceReducer,
});

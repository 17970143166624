import React from "react";
import PollStatsService from "./PollStats.service";
import { BarDatum, ResponsiveBar } from "@nivo/bar";
import "./PollStats.css";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import {
  changeNotificationMessage,
  ChangeNotificationMessageFunction,
} from "../../redux/actions";
import { NotificationType } from "../../redux/models/Notification";
import { Theme } from "@nivo/core";

const theme: Theme = {
  background: "#222222",
  text: {
    fontSize: 14,
  },
  axis: {
    ticks: {
      line: {
        stroke: "#555555",
      },
      text: {
        fill: "#ffffff",
      },
    },
    legend: {
      text: {
        fill: "#ffffff",
      },
    },
  },
  grid: {
    line: {
      stroke: "#555555",
    },
  },
  tooltip: {
    container: {
      background: "#222222",
      color: "#FFFFFF",
    },
  },
};

const ERROR_NOT_VALID_POLL_ID = "Invalid poll id";

interface MatchParams {
  pollId: string;
}
export interface PollStatsProps extends RouteComponentProps<MatchParams> {
  changeNotificationMessage: ChangeNotificationMessageFunction;
}
export interface PollStatsState {
  data: BarDatum[] | undefined;
}

class PollStats extends React.Component<PollStatsProps, PollStatsState> {
  pollStatsService: PollStatsService;

  constructor(props: PollStatsProps) {
    super(props);
    this.pollStatsService = new PollStatsService();
    this.state = {
      data: undefined,
    };
  }

  async componentDidMount(): Promise<void> {
    try {
      const pollId = this.props.match.params.pollId;
      if (pollId) {
        const pollStats = await this.pollStatsService.getPollStats(pollId);
        const barData = pollStats as unknown as BarDatum[];
        this.setState({ data: barData });
      }
    } catch (e: any) {
      if (e?.message === ERROR_NOT_VALID_POLL_ID) {
        this.props.changeNotificationMessage({
          type: NotificationType.ERROR,
          message: ERROR_NOT_VALID_POLL_ID,
        });
        return;
      }
      throw new Error(e);
    }
  }

  render() {
    return (
      <Grid container justifyContent="center">
        <Grid item xs={12}>
          {this.state.data ? (
            <Paper className="graph-container">
              <ResponsiveBar
                tooltip={undefined}
                data={this.state.data}
                keys={["vote"]}
                indexBy="value"
                margin={{ top: 50, right: 130, bottom: 50, left: 60 }}
                padding={0.3}
                theme={theme}
                colors={{ scheme: "dark2" }}
                defs={[
                  {
                    id: "dots",
                    type: "patternDots",
                    background: "inherit",
                    color: "#38bcb2",
                    size: 4,
                    padding: 1,
                    stagger: true,
                  },
                  {
                    id: "lines",
                    type: "patternLines",
                    background: "inherit",
                    color: "#eed312",
                    rotation: -45,
                    lineWidth: 6,
                    spacing: 10,
                  },
                ]}
                fill={[
                  {
                    match: {
                      id: "fries",
                    },
                    id: "dots",
                  },
                  {
                    match: {
                      id: "sandwich",
                    },
                    id: "lines",
                  },
                ]}
                borderColor={{ from: "color", modifiers: [["darker", 1.6]] }}
                axisTop={null}
                axisRight={null}
                axisBottom={{
                  tickSize: 5,
                  tickPadding: 5,
                  tickRotation: 0,
                  legend: "Selected Option",
                  legendPosition: "middle",
                  legendOffset: 32,
                }}
                axisLeft={{
                  tickSize: 5,
                  tickPadding: 5,
                  tickRotation: 0,
                  legend: "Votes",
                  legendPosition: "middle",
                  legendOffset: -40,
                }}
                labelSkipWidth={12}
                labelSkipHeight={12}
                labelTextColor={{ from: "color", modifiers: [["darker", 1.6]] }}
                legends={[
                  {
                    dataFrom: "keys",
                    anchor: "bottom-right",
                    direction: "column",
                    justify: false,
                    translateX: 120,
                    translateY: 0,
                    itemsSpacing: 2,
                    itemWidth: 100,
                    itemHeight: 20,
                    itemDirection: "left-to-right",
                    itemOpacity: 0.85,
                    symbolSize: 20,
                    effects: [
                      {
                        on: "hover",
                        style: {
                          itemOpacity: 1,
                        },
                      },
                    ],
                    itemTextColor: "#ffffff",
                  },
                ]}
                isInteractive={true}
                animate={true}
                motionConfig="gentle"
              />
            </Paper>
          ) : (
            ""
          )}
        </Grid>
      </Grid>
    );
  }
}

export default withRouter(
  connect(null, {
    changeNotificationMessage: changeNotificationMessage,
  })(PollStats),
);

import { lazy } from "react";
import youtubeLogo from "../img/logo-youtube.svg";
import radioIcon from "../img/radio-antenna.svg";
import timeCalculatorIcon from "../img/time-card.png";
import newsIcon from "../img/news.svg";
import mapIcon from "../img/map.svg";
import calculatorIcon from "../img/calculator.svg";
import PollImage from "../img/round_poll_black_48dp.png";
import RecipeImage from "../img/arrangement-delicious-ingredients-kitchen-resized.jpg";
import PokerImg from "../img/poker.png";
import BlogImg from "../img/blog-image.webp";

const Home = lazy(() => import("../component/home/Home"));
const Project = lazy(() => import("../component/project/Project"));
const Calculator = lazy(() => import("../component/calculator/Calculator"));
const Radio = lazy(() => import("../component/radio/Radio"));
const TimeCalculator = lazy(
  () => import("../component/time-calculator/TimeCalculator"),
);
// const RandomWebpage = lazy(
//   () => import("../component/random-webpage/RandomWebpage"),
// );
const News = lazy(() => import("../component/news/News"));
const MapBox = lazy(() => import("../component/MapBox"));
const YoutubePlayer = lazy(
  () => import("../component/youtube-player/YoutubePlayer"),
);
const Poll = lazy(() => import("../component/poll/Poll"));

// const Account = lazy(() => import("../component/account/Account"));
// const TimeRecorder = lazy(
//   () => import("../component/time-recorder/TimeRecorder")
// );
const SubscribeRecipe = lazy(
  () => import("../component/recipe/SubscribeRecipe"),
);

export type NavigationMap = {
  key: string;
  title: string;
  path: string;
  component: any;
  image?: {
    resource: any;
    title: string;
  };
  body?: any;
};

export const navigationMap: NavigationMap[] = [
  {
    key: "home",
    title: "Home",
    path: "/",
    component: Home,
  },
  {
    key: "project",
    title: "Project",
    path: "/project",
    component: Project,
  },
  {
    key: "youtube",
    title: "Youtube Auto Replay",
    path: "/project/youtube",
    component: YoutubePlayer,
    image: {
      resource: youtubeLogo,
      title: "Youtube Logo",
    },
    body: "Watch looping youtube videos without using extra data when the video loops.",
  },
  //FIXME implement websocket in backend
  // {
  //   title: "Chat",
  //   path: "/project/chat",
  //   component: Chat,
  //   image: {
  //     resource: chatIcon,
  //     title: "Chat Icon"
  //   },
  //   body: <p>Group chat application</p>
  // },
  {
    key: "radio",
    title: "Radio Stations",
    path: "/project/radio",
    component: Radio,
    image: {
      resource: radioIcon,
      title: "Radio Icon",
    },
    body: "Streamable Online Radio Stations",
  },
  {
    key: "poll",
    title: "Poll",
    path: "/project/poll",
    component: Poll,
    image: {
      resource: PollImage,
      title: "Poll",
    },
    body: "Start a new poll",
  },
  //TODO needs to be fixed in the backend
  // {
  //   key: "random-webpage",
  //   title: "Random Webpage Generator",
  //   path: "/project/random-webpage",
  //   component: APIResponseHOC(
  //     RandomWebpage,
  //     process.env.REACT_APP_API_URL,
  //     "/random-webpage",
  //     null,
  //     "GET"
  //   ),
  //   image: {
  //     resource: randomWebpageIcon,
  //     title: "Random Webpage Icon"
  //   },
  //   body: "Generate a random webpage"
  // },
  {
    key: "news",
    title: "Daily News",
    path: "/project/news",
    component: News,
    image: {
      resource: newsIcon,
      title: "News Icon",
    },
    body: "Read Daily News",
  },
  {
    key: "map",
    title: "Map",
    path: "/project/map",
    component: MapBox,
    image: {
      resource: mapIcon,
      title: "Map Icon",
    },
    body: "Get Navigation Directions to your destination",
  },
  {
    key: "time-card",
    title: "Time Card / Difference Calculator",
    path: "/project/time-calculator",
    component: TimeCalculator,
    image: {
      resource: timeCalculatorIcon,
      title: "Time Calculator Icon",
    },
    body: "Calculate hours worked",
  },
  {
    key: "converter",
    title: "Converter",
    path: "/project/calculator",
    component: Calculator,
    image: {
      resource: calculatorIcon,
      title: "Calculator Icon",
    },
    body: "Metric to imperial converter",
  },
  {
    key: "recipe",
    title: "Recipes",
    path: "/project/recipe",
    component: SubscribeRecipe,
    image: {
      resource: RecipeImage,
      title: "Recipe Image",
    },
    body: "Subscribe to daily recipes to your email",
  },
  // {
  //   title: "Sign in",
  //   path: "/project/account",
  //   component: Account,
  //   image: {
  //     resource: calculatorIcon,
  //     title: "Account Icon"
  //   },
  //   body: "Sign in to your account"
  // },
  //FIXME broken currently
  // {
  //   key: "time-recorder",
  //   title: "Time Recorder",
  //   path: "/project/time-recorder",
  //   component: TimeRecorder,
  //   image: {
  //     resource: calculatorIcon,
  //     title: "Time Recorder Icon"
  //   },
  //   body: "Custom Time Recorder"
  // }
];

export const externalNavigationMap = [
  {
    link: process.env.REACT_APP_SCRUM_LINK || "",
    title: "Planning Poker",
    body: "Used to conduct online planning poker sessions for your team",
    image: PokerImg,
    imageTitle: "Planning Poker Icon",
    key: "poker",
  },
  {
    link: process.env.REACT_APP_BLOG_LINK || "",
    title: "Blog",
    body: "Blog on technical tips",
    image: BlogImg,
    imageTitle: "Blog icon",
    key: "blog",
  },
  {
    link: process.env.REACT_APP_CONNECT_FOUR_LINK || "",
    title: "Connect Four",
    body: "Connect four game made in unity",
    image: undefined,
    imageTitle: "Connect Four icon",
    key: "connect-four",
  },
];

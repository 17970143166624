import { IAction } from "../actions";
import { CHANGE_NOTIFICATION_MESSAGE } from "../actionTypes";

const initialState = {
  type: undefined,
  message: undefined,
};

export default function notificationMessageReducer(
  state = initialState,
  action: IAction,
) {
  switch (action.type) {
    case CHANGE_NOTIFICATION_MESSAGE: {
      const { notification } = action.payload;
      return {
        ...state,
        ...notification,
      };
    }
    default:
      return state;
  }
}

import { CHANGE_ACCOUNT, CHANGE_NOTIFICATION_MESSAGE } from "./actionTypes";
import { Notification } from "./models/Notification";

export interface IAction {
  type: any;
  payload?: any;
}

export interface ChangeNotificationMessageFunction {
  (notification: Notification): void;
}

export const changeNotificationMessage: ChangeNotificationMessageFunction = (
  notification: Notification
) => {
  return {
    type: CHANGE_NOTIFICATION_MESSAGE,
    payload: {
      notification: notification,
    },
  };
};

export interface ChangeAccountAction {
  type: typeof CHANGE_ACCOUNT;
  payload?: {
    account: any;
  };
}

export const changeAccount = (account: any): ChangeAccountAction => {
  return {
    type: CHANGE_ACCOUNT,
    payload: {
      account: account,
    },
  };
};

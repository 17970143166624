import { IPollStats } from "../../dto/poll/IPollStats";
import { IGetPollStatsResponseDto } from "../../dto/poll/IGetPollStatsResponseDto";
import {
  IErrorMessageDto,
  isErrorMessageDto,
} from "../../dto/IErrorMessageDto";

export const allowListErrorMessagesGetPollStats = [
  "Need an id to fetch stats",
  "Failed to find poll stats",
];

class PollStatsService {
  async getPollStats(pollId: string): Promise<IPollStats[] | undefined> {
    return fetch(`${process.env.REACT_APP_API_URL}/poll/stats?id=${pollId}`, {
      method: "GET",
    })
      .then((res) => res.json())
      .then((jsonResponse: IGetPollStatsResponseDto | IErrorMessageDto) => {
        if (isErrorMessageDto(jsonResponse)) {
          throw new Error(jsonResponse.error);
        }

        return jsonResponse.stats;
      });
  }
}

export default PollStatsService;

import React, { Suspense } from "react";
import {
  Route,
  RouteComponentProps,
  Switch,
  withRouter,
} from "react-router-dom";
import NavigationBar from "./component/navigation-bar/NavigationBar";
import "./App.css";
import "./component/nav.css";
import "./animate.css";
/* https://reacttraining.com/react-router/web/api/withRouter */
import PropTypes from "prop-types";
import Article from "./component/news/article/Article";
import { createTheme, MuiThemeProvider } from "@material-ui/core/styles";
import { deepOrange, orange } from "@material-ui/core/colors";
import CssBaseline from "@material-ui/core/CssBaseline/CssBaseline";
import { NavigationMap, navigationMap } from "./service/NavigationMap";
import { regexReplace } from "./component/Services";
import { APIResponseHOC } from "./component/APIResponseHOC";
import NotificationHandler from "./component/notification/NotificationHandler";
import AnswerPoll from "./component/answer-poll/AnswerPoll";
import PollStats from "./component/poll-stats/PollStats";
import { Loading } from "./component/common/Loading";
import Footer from "./component/footer/Footer";
import Grid from "@material-ui/core/Grid/Grid";
import Helmet from "react-helmet";
import NotFound from "./component/not-found/NotFound";
import { PollAdmin } from "./component/poll-admin/PollAdmin";
import { pollAdminRoutePrefix, pollAnswerRoutePrefix } from "./constants";
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getPerformance } from "firebase/performance";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
};

const firebaseApp = initializeApp(firebaseConfig);
export const firebasePerformance = getPerformance(firebaseApp);
export const firebaseAnalytics = getAnalytics(firebaseApp);
// export const firebaseAuth = getAuth(firebaseApp);

const theme = createTheme({
  palette: {
    type: "dark",
    primary: orange,
    secondary: deepOrange,
    background: {
      default: "#0e1111", //soft black
    },
  },
  typography: {
    h1: {
      fontSize: "3rem",
    },
    h2: {
      fontSize: "2rem",
    },
    h3: {
      fontSize: "1.5rem",
    },
  },
  overrides: {
    // Name of the component
    MuiButton: {
      // Name of the rule
      root: {
        // Some CSS
        // background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
        // borderRadius: 3,
        // border: 0,
        color: "white",
        // height: 48,
        // padding: '0 30px',
        // boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
      },
      containedPrimary: {
        color: "white",
      },
    },
    MuiFormHelperText: {
      root: {
        textAlign: "center",
      },
    },
    MuiGrid: {
      root: {
        textAlign: "center",
        justifyContent: "center",
        alignItems: "center",
      },
    },
    MuiCard: {
      root: {
        margin: "0 auto",
      },
    },
  },
});

interface AppProps extends RouteComponentProps<{}> {}
export type AppState = {};

class App extends React.Component<AppProps, AppState> {
  /* https://reacttraining.com/react-router/web/api/withRouter */
  static propTypes = {
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
  };

  //https://github.com/DefinitelyTyped/DefinitelyTyped/blob/f6f3254c89ea03010d51533262ce7d1094b32607/types/react-router/index.d.ts#L28
  constructor(props: AppProps) {
    super(props);

    this.onRouteChanged(this.props.location.pathname);
  }

  componentDidUpdate(prevProps: any) {
    /* check if user navigated to a different route*/
    /* https://reacttraining.com/react-router/web/api/withRouter */
    if (this.props.location.pathname !== prevProps.location.pathname) {
      this.onRouteChanged(this.props.location.pathname);
    }
  }

  componentDidMount() {}

  onRouteChanged = (pathname: string) => {};

  render() {
    return (
      <MuiThemeProvider theme={theme}>
        {/*baseline component material ui https://material-ui.com/style/css-baseline/#page*/}
        <CssBaseline />
        <React.StrictMode>
          <div className="App">
            <Helmet
              titleTemplate="Wilson Mun - %s"
              defaultTitle="Wilson Mun"
            ></Helmet>
            {/*<CssImport/>*/}

            <NavigationBar />
            <NotificationHandler />
            {/*<Header/>*/}
            {/*<Clock/>*/}
            {/*<ImageLoader/>*/}
            {/*<Bounce/>*/}

            <Suspense
              fallback={
                <Grid container>
                  <Loading />
                </Grid>
              }
            >
              <Switch>
                {navigationMap.map((navigation: NavigationMap, key: number) => {
                  return (
                    <Route
                      key={key}
                      exact
                      path={navigation.path}
                      component={navigation.component}
                    />
                  );
                })}

                <Route
                  path="/news/article/:articleId"
                  component={APIResponseHOC(
                    Article,
                    process.env.REACT_APP_API_URL,
                    "/news/article",
                    regexReplace(
                      "^/news/article/([0-9]+)$",
                      this.props.location.pathname,
                    ),
                    "GET",
                  )}
                />

                <Route
                  path={`${pollAnswerRoutePrefix}/:pollId/permission/:pollPermissionId`}
                >
                  <AnswerPoll />
                </Route>

                <Route path={`${pollAdminRoutePrefix}/:pollId`}>
                  <PollAdmin />
                </Route>

                <Route path="/poll/stats/:pollId">
                  <PollStats />
                </Route>
                {/*api search using nominatim by OpenStree tMap*/}
                {/*<Route*/}
                {/*exact path="/project/map"*/}
                {/*component={APIResponseHOC(Map,*/}
                {/*'https://nominatim.openstreetmap.org/search/',*/}
                {/*"oxley%20christian%20college",*/}
                {/*{*/}
                {/*format: 'json',*/}
                {/*polygon: 1,*/}
                {/*addressdetails: 1,*/}
                {/*countrycodes: 'au'*/}
                {/*},*/}
                {/*"GET")}/>*/}

                <Route>
                  <NotFound />
                </Route>
              </Switch>
            </Suspense>
          </div>
        </React.StrictMode>
        <Footer />
      </MuiThemeProvider>
    );
  }
}

export default withRouter((props) => <App {...props} />);

import React from "react";
// @ts-ignore
import { createRoot } from "react-dom/client";
import App from "./App";
import "./App.css";
import "./animate.css";
import { BrowserRouter } from "react-router-dom";
import * as serviceWorker from "./serviceWorker";
import { Provider } from "react-redux";
import store from "./redux/store";
import * as Sentry from "@sentry/react";
import { FallbackError } from "./component/fallback-error/FallbackError";

/**
 *  sentry monitoring exceptions
 *  https://docs.sentry.io/platforms/javascript/guides/react/
 **/
Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN || "",
  environment: process.env.NODE_ENV,
  release: process.env.REACT_APP_VERSION || "",
  integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],
  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 0.2,

  // Capture Replay for 10% of all sessions,
  // plus for 100% of sessions with an error
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

const container = document.getElementById("root");
const root = createRoot(container!);
root.render(
  <Sentry.ErrorBoundary fallback={<FallbackError />}>
    <BrowserRouter>
      <Provider store={store}>
        <App />
      </Provider>
    </BrowserRouter>
  </Sentry.ErrorBoundary>
);

// serviceWorker.register({
//   changeNotificationMessage: dispatchChangeNotifcationMessage
// });

// disable PWA
serviceWorker.unregister();

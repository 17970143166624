import React from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import "./Loading.scss";
export interface LoadingProps {
  size?: number;
  color?: "inherit" | "primary" | "secondary";
  className?: string;
}

export function Loading(props: LoadingProps) {
  const size = props.size ? props.size : 40;
  const color = props.color ? props.color : "primary";
  const className = props.className
    ? `${props.className} loading-indicator`
    : "loading-indicator";

  return (
    <React.Fragment>
      <CircularProgress size={size} color={color} className={className} />
    </React.Fragment>
  );
}

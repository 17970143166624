import React from "react";
import AnswerPollService, {
  ALLOW_LIST_ERROR_MESSAGES,
} from "./AnswerPoll.service";

import SaveIcon from "@material-ui/icons/Save";
import PollIcon from "@material-ui/icons/Poll";
import "./AnswerPoll.css";
import { connect } from "react-redux";
import {
  changeNotificationMessage,
  ChangeNotificationMessageFunction,
} from "../../redux/actions";
import { NotificationType } from "../../redux/models/Notification";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Button from "@material-ui/core/Button";
import Radio from "@material-ui/core/Radio";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { PollOption } from "../../model/poll/PollOption";
import { allowListErrorMessagesGetPollStats } from "../poll-stats/PollStats.service";

const SUBMITTED_VOTE_SUCCESS =
  "Thank you for submitting your vote on this poll";
const ERROR_NOT_VALID_POLL_ID = "Invalid poll id";

interface MatchParams {
  pollId: string;
  pollPermissionId: string;
}
export interface AnswerPollProps extends RouteComponentProps<MatchParams> {
  changeNotificationMessage: ChangeNotificationMessageFunction;
}
export interface AnswerPollState {
  radioGroupValue: PollOption;
  poll: IPoll | undefined;
}

export interface IPoll {
  id: number;
  question: string;
  options: PollOption[];
}

export function isPollObject(object: any): object is IPoll {
  return (
    object !== undefined &&
    object.question !== undefined &&
    object.options !== undefined
  );
}

export class AnswerPoll extends React.Component<
  AnswerPollProps,
  AnswerPollState
> {
  constructor(props: AnswerPollProps) {
    super(props);
    this.state = {
      radioGroupValue: {
        id: 999,
        value: "not in use",
      },
      poll: undefined,
    };
  }

  async componentDidMount(): Promise<void> {
    try {
      const pollId = this.props.match.params.pollId;
      const poll = await AnswerPollService.getPoll(pollId);

      if (isPollObject(poll)) {
        this.setState({ poll: poll });
      }
    } catch (e: any) {
      if (allowListErrorMessagesGetPollStats.includes(e?.message)) {
        this.props.changeNotificationMessage({
          type: NotificationType.ERROR,
          message: e?.message,
        });
      }
      if (e?.message === ERROR_NOT_VALID_POLL_ID) {
        this.props.changeNotificationMessage({
          type: NotificationType.ERROR,
          message: ERROR_NOT_VALID_POLL_ID,
        });
        return;
      }
      throw new Error(e);
    }
  }

  changeSelectedPollOption = (event: React.ChangeEvent<HTMLInputElement>) => {
    const currentPoll = this.state.poll;
    if (!currentPoll) {
      return;
    }

    //find pollOption
    const pollOption = currentPoll.options.find((pollOption: PollOption) => {
      return pollOption.value === event.target.value;
    });
    if (!pollOption) {
      return;
    }

    this.setState({ radioGroupValue: pollOption });
  };

  addNewVote = async () => {
    if (!this.state.poll || !this.state.radioGroupValue) {
      return;
    }
    const selectedValue = this.state.radioGroupValue;

    const pollId = this.props.match.params.pollId;
    const pollPermissionId = this.props.match.params.pollPermissionId;

    try {
      const saveVoteResult = await AnswerPollService.saveVote(
        pollId,
        selectedValue.id,
        pollPermissionId,
      );

      if (saveVoteResult) {
        this.props.changeNotificationMessage({
          type: NotificationType.SUCCESS,
          message: SUBMITTED_VOTE_SUCCESS,
        });
      }
    } catch (e: any) {
      if (ALLOW_LIST_ERROR_MESSAGES.includes(e?.message)) {
        this.props.changeNotificationMessage({
          type: NotificationType.ERROR,
          message: e?.message,
        });
      } else {
        throw new Error(e);
      }
    }
  };

  navigateToPollStats = () => {
    const pollId = this.props.match.params.pollId;
    this.props.history.push(`/poll/stats/${pollId}`);
  };

  render() {
    return (
      <React.Fragment>
        <Grid container>
          <Paper elevation={6}>
            {this.state.poll ? (
              <Grid item xs={12}>
                <FormControl component="fieldset">
                  <FormLabel component="legend">
                    Poll: {this.state.poll.question}
                  </FormLabel>
                  <RadioGroup
                    aria-label={this.state.radioGroupValue.value}
                    name={this.state.radioGroupValue.value + "radio"}
                    value={this.state.radioGroupValue.value}
                    onChange={this.changeSelectedPollOption}
                  >
                    {this.state.poll.options.map((pollOption, key) => {
                      return (
                        <FormControlLabel
                          key={key}
                          value={pollOption.value}
                          control={<Radio />}
                          label={pollOption.value}
                        />
                      );
                    })}
                  </RadioGroup>
                </FormControl>
                <Grid item xs={12}>
                  <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    startIcon={<SaveIcon />}
                    onClick={this.addNewVote}
                  >
                    Submit vote
                  </Button>
                </Grid>
                <Grid item xs={12}>
                  <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    startIcon={<PollIcon />}
                    onClick={this.navigateToPollStats}
                    data-testid="stats-button"
                  >
                    Get stats
                  </Button>
                </Grid>
              </Grid>
            ) : (
              ""
            )}
          </Paper>
        </Grid>
      </React.Fragment>
    );
  }
}

export default withRouter(
  connect(null, {
    changeNotificationMessage: changeNotificationMessage,
  })(AnswerPoll),
);

import { ChangeAccountAction } from "../actions";
import { CHANGE_ACCOUNT } from "../actionTypes";

export default function accountReducer(
  state: any = { account: undefined },
  action: ChangeAccountAction,
): any {
  switch (action.type) {
    case CHANGE_ACCOUNT: {
      if (!action.payload) {
        return state;
      }
      const { account } = action.payload;

      return {
        ...state,
        account: account,
      };
    }
    default:
      return state;
  }
}

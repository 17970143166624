import React from "react";

export interface APIResponseHOCClassProps {
  data: any;
  isLoaded: boolean;
  error: boolean;
}

export interface APIResponseHOCClassState {}
/* Accepts a Component and return a component with api call */
export function APIResponseHOC(
  WrappedComponent: any,
  baseUrl: string | undefined,
  endpoint: string,
  endpointParams: any,
  requestMethod: string
) {
  return class APIResponseHOCClass extends React.Component<
    APIResponseHOCClassProps,
    APIResponseHOCClassState
  > {
    BASE_URL: string;
    endpoint: string;
    endpointParams: string[];
    requestMethod: string;

    constructor(props: APIResponseHOCClassProps) {
      super(props);
      this.BASE_URL = baseUrl ? baseUrl : "http://localhost:8080"; //fallback value in case env variable does not exist
      this.endpoint = endpoint;
      this.endpointParams = endpointParams;
      this.requestMethod = requestMethod;
      this.state = {
        // store api response in the data state
        // and pass down as props to component
        data: undefined,
        isLoaded: false,
        error: false,
      };
    }

    componentDidMount() {
      let urlToFetch;
      if (this.endpointParams) {
        urlToFetch = `${this.BASE_URL}${this.endpoint}?`;
        /* loop through endpoint params and append to url to call */
        let counter = 0;
        for (let key in this.endpointParams) {
          /* append all params to url */
          urlToFetch += `${key}=${this.endpointParams[key]}`;
          counter++;
          //if it is not the last param to add to url, we append the & char to the params
          if (counter !== Object.values(this.endpointParams).length) {
            urlToFetch += "&";
          }
        }
      } else {
        urlToFetch = `${this.BASE_URL}${this.endpoint}`;
      }
      fetch(urlToFetch, {
        method: this.requestMethod,
        headers: getHeaders(),
      })
        .then(
          (res) => {
            return res.json();
          },
          (error) => {
            this.setState({
              isLoaded: true,
              error,
            });
          }
        )
        .then(
          (jsonResponse) => {
            this.setState({
              data: jsonResponse,
              isLoaded: true,
            });
          },
          /* handles errors here */
          (error) => {
            this.setState({
              isLoaded: true,
              error,
            });
          }
        );
    }

    render() {
      return <WrappedComponent {...this.state} />;
    }
  };
}

export function externalAPI(
  BASE_URL: string,
  endpoint: string,
  endpointParams: object,
  requestMethod: string
): any {
  return new Promise((resolve, reject) => {
    let urlToFetch;
    if (endpointParams) {
      urlToFetch = `${BASE_URL}${endpoint}?`;
      /* loop through endpoint params and append to url to call */
      let counter = 0;
      for (let key in endpointParams) {
        /* append all params to url */
        // @ts-ignore
        urlToFetch += `${key}=${endpointParams[key]}`;
        counter++;
        //if it is not the last param to add to url, we append the & char to the params
        if (counter !== Object.values(endpointParams).length) {
          urlToFetch += "&";
        }
      }
    } else {
      urlToFetch = `${BASE_URL}${endpoint}`;
    }
    fetch(urlToFetch, {
      method: requestMethod,
      headers: getHeaders(),
    })
      .then((res) => res.json())
      .then(
        (jsonResponse) => {
          resolve(jsonResponse);
        },
        /* handles errors here */
        (error) => {
          reject(error);
        }
      );
  });
}

export function getHeaders() {
  return { Accept: "application/json", "Content-Type": "application/json" };
}

import axios from "axios";
import { ICreatePollVotePermissionRequestBodyDto } from "../../dto/poll/ICreatePollVotePermissionRequestBodyDto";
import {
  ICreatePollVotePermissionResponseDto,
  isCreatePollVotePermissionResponseDto,
} from "../../dto/poll/ICreatePollVotePermissionResponseDto";

export async function createPollVotePermission(
  pollIdString: string
): Promise<ICreatePollVotePermissionResponseDto | undefined> {
  const body: ICreatePollVotePermissionRequestBodyDto = {
    pollId: pollIdString,
  };
  const result = await axios.post(
    `${process.env.REACT_APP_API_URL}/poll/permission`,
    body
  );
  if (isCreatePollVotePermissionResponseDto(result.data)) {
    return result.data as ICreatePollVotePermissionResponseDto;
  }

  return undefined;
}
